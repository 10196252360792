import { Component } from '@angular/core';

@Component({
  selector: 'bazaarna-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent {
  constructor() {
    // Todo
  }
}
