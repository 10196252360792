import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataFoundComponent } from './no-data-found.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [NoDataFoundComponent],
  imports: [CommonModule, TranslateModule],
  exports:[NoDataFoundComponent]
})
export class NoDataFoundModule { }
