import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderDetail } from '../models/orderDetail';
import { OrderResponse } from '../models/orderResponse';
import { ReturnOrder } from '../models/returnOrder';
import { Order } from '../models/order';
import { ExportCsvResponse } from '../models/exportCsvResponse';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  getOrder(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/getOrdersDispatcher?${query}`
      )
      .pipe(map(res => res.data));
  }

  groupOrders(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/groupOrders?${query}`
      )
      .pipe(map(res => res.data));
  }

  getOrderById(id: string): Observable<OrderDetail> {
    return this.http
      .get<{ data: OrderDetail }>(
        `${environment.base_url}admin/getDetailsByOrderId/${id}`
      )
      .pipe(map(res => res.data));
  }

  productsAgainstExchange(id: string): Observable<OrderDetail> {
    return this.http
      .get<{ data: OrderDetail }>(
        `${environment.base_url}admin/productsAgainstExchange/${id}`
      )
      .pipe(map(res => res.data));
  }

  getOrderForExchangeReturn(query: string): Observable<OrderResponse> {
    return this.http
      .get<{ data: OrderResponse }>(
        `${environment.base_url}admin/exchangeReturnDispatcher?${query}`
      )
      .pipe(map(res => res.data));
  }

  updateReturnOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{ data: Order }>(
        `${environment.base_url}admin/updateReturnOrder`,
        payload
      )
      .pipe(map(res => res.data));
  }

  updateExchangeOrder(payload: ReturnOrder): Observable<Order> {
    return this.http
      .put<{ data: Order }>(
        `${environment.base_url}admin/updateExchangeOrder`,
        payload
      )
      .pipe(map(res => res.data));
  }

  assignOrderToDriver(
    payload: { driverId: string },
    orderId: string
  ): Observable<any> {
    return this.http.post(
      `${environment.base_url}admin/assignDriver/${orderId}`,
      payload
    );
  }

  exportCsv(orderStatus: string): Observable<ExportCsvResponse> {
    return this.http.get<ExportCsvResponse>(
      `${environment.base_url}admin/getOrdersDispatcherCSV?status=${orderStatus}`
    );
  }
}
