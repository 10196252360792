import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitialService {
  script = document.createElement('script');
  async setUpGoogleScript(): Promise<void> {
    setTimeout(() => {
      // Set Google Api keY DYNAMICALLY
      const head = document.getElementsByTagName('body')[0];

      if (!document.getElementById('testScriptName')) {
        this.script.type = 'text/javascript';
        this.script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleKey}&libraries=places,drawing&language=en`;
        this.script.id = 'newTestScriptName';
        this.script.async = true;
        head.appendChild(this.script);
      }
    }, 100);
  }
}
