<div class="modal_title">
  <h1>{{ 'AddTemplateContent' | translate }}</h1>
</div>
<div class="form_feilds">
  <div class="form_grup w_100">
    <mat-label>{{ 'TemplateType' | translate }}</mat-label>
    <mat-form-field appearance="fill">
      <mat-select placeholder="Select Template">
        <mat-option value="1">On-Boarding</mat-option>
        <mat-option value="2">Order by form</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
<mat-label>{{ 'TemplateContent' | translate }}</mat-label>
<ckeditor [data]="content" [config]="config"></ckeditor>
<button type="button" class="btn btn-primary mt_20">{{ 'Update' | translate }}</button>
