import { Component } from '@angular/core';
import { assetsConst } from 'src/app/core/const/assets.const';

@Component({
  selector: 'bazaarna-nodatafound',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent {

  assetConst = assetsConst;

}
