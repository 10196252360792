/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SocketService } from 'src/app/services/socket.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { assetsConst } from 'src/app/core/const/assets.const';
import { logoutResponse } from 'src/app/models/logoutResponse';
import { responseStatus } from 'src/app/core/const/responseStatus.const';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bazaarna-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit, OnDestroy {
  sidenavWidth = 18;
  subscriptionSubject$!: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  profileDetails: any;
  assetsImages = assetsConst;
  audio = new Audio();
  src = 'assets/sounds/notification-sound1.mp3';

  constructor(
    private _authService: AuthService,
    private _spinnerService: SpinnerService,
    private _socketService: SocketService,
    private _toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this._socketService.initSocket();
  }

  ngOnInit(): void {
    this.subscriptionSubject$ = this._authService.profileDetails
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any | null) => {
        if (res !== null) {
          this.profileDetails = res;
        } else {
          this.getProfileDetails();
        }
      });

    this.handleListenersAndEmitters();
    this.handleOrderStatusListener();
  }

  handleOrderStatusListener(): void {
    this._socketService
      .onEvent('dashboardOk')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.playAudio();
        this._toasterService.successToastr(
          this.translateService.instant('Assign driver to new Order')
        );
      });
  }

  handleListenersAndEmitters(): void {
    this._socketService
      .onEvent('changeOrderStatus')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.playAudio();
        this._toasterService.successToastr(
          this.translateService.instant('New Order Received')
        );
      });
  }

  playAudio(): void {
    this.audio.src = this.src;
    this.audio.load();
    this.audio.play();
    this.audio.muted = false;
  }

  getProfileDetails(): void {
    this._spinnerService.addToLoader('getProfile');
    this._authService
      .getAdminProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this._spinnerService.removeFromLoader('getProfile');
          this.profileDetails = res;
          this._authService.profileDetails.next(res);
        },
        error: () => {
          this._spinnerService.removeFromLoader('getProfile');
        }
      });
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this._spinnerService.addToLoader('logout');
        this._authService
          .logout()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: logoutResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                this._socketService.disconnect();
                localStorage.removeItem(environment.storageKey);
                this._spinnerService.removeFromLoader('logout');
                this.router.navigate(['/auth/login']);
              }
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    this.subscriptionSubject$.unsubscribe();
  }
}
