export const orderStatusKeysConst = {
  Pending: 1,
  Accepted: 2,
  ReadyToPick: 3,
  PickedByDriver: 4,
  OutforDelivery: 5,
  Delivered: 6,
  NotDelivered: 7,
  Rejected: 8,
  Cancelled: 9,
  Refunded: 10,
  UnAssigned: 11,
  ReturnRequested: 12,
  ExchangeRequested: 13,
  Returned: 14,
  ReadyToPickCustomer: 15,
  ReturnAccepted: 16,
  ReturnRejected: 17,
  SUBSTITUTE: 18,
  ExchangeAccepted: 19,
  ExchangeRejected: 20,
  RETURN_PICKED_BY_DRIVER: 22,
  RETURN_DROPED_BY_DRIVER: 23,
  EXCHANGE_PICKED_BY_DRIVER: 24,
  EXCHANGE_DROPED_BY_DRIVER: 25
};
