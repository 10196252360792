import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class ToasterService {
  constructor(
    private toastr: ToastrService
  ) {
    // To Do
  }

  successToastr(message: string): void {
    this.toastr.success(message);
  }

  errToastr(message: string):void {
    this.toastr.error(message);
  }

  infoToastr(message: string):void {
    this.toastr.info(message);
  }
}
