import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ViewTemplateComponent } from './view-template/view-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageviewerComponent } from './imageviewer/imageviewer.component';
import { AssignDriverComponent } from './assign-driver/assign-driver.component';
import { MatTableModule } from '@angular/material/table';
import { NoDataFoundModule } from '../components/no-data-found/no-data-found.module';
import { RejectionReasonComponent } from './rejection-reason/rejection-reason.component';
import { OrderInvoiceComponent } from './order-invoice/order-invoice.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ViewTemplateComponent,
    EditTemplateComponent,
    AddNotificationComponent,
    AddVehicleComponent,
    ImageviewerComponent,
    AssignDriverComponent,
    RejectionReasonComponent,
    OrderInvoiceComponent,
  ],
  exports: [OrderInvoiceComponent],

  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    CKEditorModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    FormsModule,
    NoDataFoundModule,
    MatTooltipModule,
    PipesModule,
  ],
})
export class ModalsModule {}
