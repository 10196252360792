import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

export interface Element {
  position: number;
  question: string;
  answer: string;
  action: string;
}
const ELEMENT_DATA: Element[] = [
  {
    position: 1,
    question: 'lorem ipsum',
    answer: 'lorem ipsum dolor sit amet .......',
    action: ''
  }
];
@Component({
  selector: 'bazaarna-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent {
  displayedColumns = ['position', 'question', 'answer', 'action'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);

  // toolbar: [
  //   'heading',
  //   '|',
  //   'bold',
  //   'italic',
  //   'link',
  //   'bulletedList',
  //   'numberedList',
  //   'blockQuote'
  // ];
  // cmsType: any;
  // public Editor: any = ClassicEditor;

  constructor(private dialog: MatDialog) {}

  content = '';
  config = {
    // extraPlugins: "autogrow",
    // resize_enabled: false,
    // autoGrow_maxHeight: 200,
    // autoGrow_maxWidth: 200,
    // removePlugins: "resize"
  };
}
