<div *ngIf="orderDetails && orderDetails.subOrders" class="pop_mn card">
  <div class="card-body">
    <h2>{{ 'OrderNumber' | translate }} : {{ orderDetails?._id }}</h2>

    <div
      *ngIf="orderDetails.subOrders[0]?.status !== orderStatus.Pending"
      class="invoice_mn">
      <div class="invoice_lft invoice_ryt">
        <h3>{{ 'MerchantDetail' | translate }}</h3>
        <p>
          <strong>{{ 'MerchantName' | translate }} :</strong>
          {{ orderDetails.subOrders[0]?.merchant?.name }}
        </p>
        <p>
          <strong>{{ 'PhoneNumber' | translate }} :</strong>
          {{
            orderDetails.subOrders[0]?.merchant?.countryCode +
              ' ' +
              orderDetails.subOrders[0]?.merchant?.phone
          }}
        </p>
        <p>
          <strong>{{ 'Email' | translate }} :</strong>
          {{ orderDetails.subOrders[0]?.merchant?.email }}
        </p>
      </div>

      <ng-container
        *ngIf="
          orderDetails.subOrders[0]?.user?.role === 'bazaarnaUser';
          else guest
        ">
        <div class="invoice_ryt">
          <h3>{{ 'UserDetail' | translate }}</h3>
          <p>
            <strong>{{ 'UserName' | translate }} :</strong>
            {{ orderDetails.subOrders[0]?.user?.name }}
          </p>
          <p>
            <strong>{{ 'PhoneNumber' | translate }} : </strong
            >{{
              orderDetails.subOrders[0]?.user?.countryCode +
                ' ' +
                orderDetails.subOrders[0]?.user?.phone
            }}
          </p>
          <p>
            <strong>{{ 'Email' | translate }} :</strong>
            {{ orderDetails.subOrders[0]?.user?.email }}
          </p>
        </div>
      </ng-container>
      <ng-template #guest>
        <div class="invoice_ryt">
          <h3>{{ 'UserDetail' | translate }}</h3>
          <p>
            <strong>{{ 'UserName' | translate }} :</strong>
            {{ orderDetails.subOrders[0]?.address?.name }}
          </p>
          <p>
            <strong>{{ 'PhoneNumber' | translate }} : </strong
            >{{
              orderDetails.subOrders[0]?.address?.countryCode +
                ' ' +
                orderDetails.subOrders[0]?.address?.phone
            }}
          </p>
          <p>
            <strong>{{ 'Email' | translate }} :</strong>
            {{ orderDetails.subOrders[0]?.address?.email }}
          </p>
        </div>
      </ng-template>
    </div>

    <div
      *ngFor="let order of orderDetails.subOrders; let i = index"
      class="invoice_ryt parent_invoice">
      <h3>
        {{ i + 1 + '.' }}
        <!-- <span>{{ 'OrderID' | translate }}: {{ ' ' }} {{ order?.orderId }}</span> -->
      </h3>

      <ul class="order_item">
        <li>
          <div class="prdct_dtl">
            <p>
              <span
                [matTooltip]="'Category' | translate"
                matTooltipPosition="above">
                {{ order?.categoryId?.name | titlecase }} {{ '>' }}
              </span>

              <span
                [matTooltip]="'SubCategory' | translate"
                matTooltipPosition="above">
                {{ order?.subCategoryId?.name }}</span
              >
            </p>
            <div class="d_flx">
              <h4 *ngIf="order && order?.basePriceOfProduct && order?.quantity">
                {{ order?.productName }}
                <span>
                  {{
                    '(' +
                      order?.basePriceOfProduct +
                      '*' +
                      order?.quantity +
                      ')'
                  }}</span
                >
              </h4>
            </div>
          </div>
          <div class="order_right">
            <div class="form_grup">
              <mat-form-field appearance="fill">
                <ng-container>
                  <mat-select
                    placeholder="{{ 'Request' | translate }}"
                    [value]="order.status"
                    (selectionChange)="onStatusChange($event, order)">
                    <mat-option
                      [value]="orderStatus.Pending"
                      *ngIf="order.status === orderStatus.Pending"
                      >{{ 'Pending' | translate }}</mat-option
                    >
                    <mat-option
                      [value]="orderStatus.Accepted"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted
                      "
                      >{{ 'Accept' | translate }}</mat-option
                    >
                    <mat-option
                      [value]="orderStatus.ReadyToPick"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick
                      "
                      [disabled]="order.status === orderStatus.Pending">
                      {{ 'ReadyToPick' | translate }}
                    </mat-option>

                    <mat-option
                      [value]="orderStatus.PickedByDriver"
                      *ngIf="
                        (order.status === orderStatus.Pending ||
                          order.status === orderStatus.Accepted ||
                          order.status === orderStatus.ReadyToPick ||
                          order.status === orderStatus.PickedByDriver) &&
                        order.deliveryBy === deliveryConst.myAddress
                      "
                      [disabled]="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted
                      ">
                      {{ 'PickedByDriver' | translate }}
                    </mat-option>

                    <mat-option
                      [value]="orderStatus.OutforDelivery"
                      *ngIf="
                        (order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick ||
                        order.status === orderStatus.PickedByDriver ||
                        order.status === orderStatus.OutforDelivery) &&
                        order.deliveryBy === deliveryConst.myAddress
                      "
                      [disabled]="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted
                      ">
                      {{ 'OutForDelivery' | translate }}
                    </mat-option>

                    <mat-option
                      [value]="orderStatus.Delivered"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick ||
                        order.status === orderStatus.PickedByDriver ||
                        order.status === orderStatus.OutforDelivery ||
                        order.status === orderStatus.Delivered
                      "
                      [disabled]="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted
                      ">
                      {{ 'Delivered' | translate }}
                    </mat-option>

                    <mat-option
                      [value]="orderStatus.NotDelivered"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick ||
                        order.status === orderStatus.PickedByDriver ||
                        order.status === orderStatus.OutforDelivery ||
                        order.status === orderStatus.NotDelivered
                      "
                      [disabled]="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted
                      ">
                      {{ 'NotDelivered' | translate }}
                    </mat-option>
                    <mat-option
                      [value]="orderStatus.Rejected"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick ||
                        order.status === orderStatus.PickedByDriver ||
                        order.status === orderStatus.OutforDelivery ||
                        order.status === orderStatus.NotDelivered ||
                        order.status === orderStatus.Rejected
                      "
                      >{{ 'Reject' | translate }}</mat-option
                    >
                    <mat-option
                      [value]="orderStatus.Cancelled"
                      *ngIf="
                        order.status === orderStatus.Pending ||
                        order.status === orderStatus.Accepted ||
                        order.status === orderStatus.ReadyToPick ||
                        order.status === orderStatus.PickedByDriver ||
                        order.status === orderStatus.OutforDelivery ||
                        order.status === orderStatus.NotDelivered ||
                        order.status === orderStatus.Cancelled
                      "
                      [disabled]="true"
                      >{{ 'Cancel' | translate }}</mat-option
                    >
                    <mat-option
                      (click)="suggestSubstitute(order._id)"
                      *ngIf="order.status !== orderStatus.Delivered"
                      >{{ 'SubstituteSuggest' | translate }}</mat-option
                    >
                  </mat-select>
                </ng-container>
              </mat-form-field>
            </div>
            <span>
              {{
                order?.basePriceOfProduct * order?.quantity | customCurrency
              }}</span
            >
          </div>
        </li>

        <li *ngIf="order" class="footer_dtl">
          <ng-container
            *ngIf="
              order?.isbazaarnaPackagingPriceApplied &&
              order?.bazaarnaPackagePrice !== 0
            ">
            <div class="prdct_dtl">
              <span>{{ 'BAZAARNAGift' | translate }}</span>
            </div>
            <p>{{ order?.bazaarnaPackagePrice | customCurrency }}</p>
          </ng-container>
          <ng-container
            *ngIf="
              order?.isbasePackagingPriceApplied &&
              order?.basePackagePrice !== 0
            ">
            <div class="prdct_dtl">
              <span>{{ 'standardPackage' | translate }}</span>
            </div>
            <p>{{ order?.basePackagePrice | customCurrency }}</p>
          </ng-container>
        </li>
      </ul>
    </div>

    <ng-container
      *ngIf="
        orderDetails.subOrders[0]?.deliveryNote &&
        orderDetails.subOrders[0]?.status !== orderStatus.Pending
      ">
      <h4>
        <p style="font-weight: 600">{{ 'deliveryNote' | translate }} :</p>
        {{ orderDetails.subOrders[0]?.deliveryNote }}
      </h4>
    </ng-container>

    <div *ngIf="showOrderSummary" class="prdct_dtl">
      <h2>{{ 'Ordersummary' | translate }}</h2>

      <h4>
        {{ 'Total' | translate }} :
        {{ fetchTotalAmount() | customCurrency }}
      </h4>

      <ng-container
        *ngIf="
          orderDetails.subOrders[0]?.isbazaarnaPackagingPriceApplied &&
          orderDetails.subOrders[0]?.bazaarnaPackagePrice !== 0
        ">
        <h4>
          {{ 'BAZAARNAGift' | translate }} :
          {{ fetchGiftAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container
        *ngIf="
          orderDetails.subOrders[0]?.isbasePackagingPriceApplied &&
          orderDetails.subOrders[0]?.basePackagePrice !== 0
        ">
        <h4>
          {{ 'standardPackage' | translate }} :
          {{ fetchBaseAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchVatAmount() > 0">
        <h4>
          {{ 'vatAmount' | translate }} :
          {{ fetchVatAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchDeliveryAmount() > 0">
        <h4>
          {{ 'DeliveryCharge' | translate }} :
          {{ fetchDeliveryAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchTipAmount() > 0">
        <h4>
          {{ 'tipAmount' | translate }} :
          {{ fetchTipAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchSaleDiscount() > 0">
        <h4 style="color: #ff4400">
          {{ 'saleDiscount' | translate }} :
          {{ fetchSaleDiscount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchCouponAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'Discount' | translate }} :
          {{ fetchCouponAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchGiftCardAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'giftCardAmount' | translate }} :
          {{ fetchGiftCardAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchLoyaltyAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'loyaltyAmount' | translate }} :
          {{ fetchLoyaltyAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchWalletOrderAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'walletAmount' | translate }} :
          {{ fetchWalletOrderAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchCancelledOrderAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'CancelledAmount' | translate }} :
          {{ fetchCancelledOrderAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container
        *ngIf="orderDetails.subOrders[0]?.status === orderStatus.Rejected">
        <h4 style="color: #ff4400">
          {{ 'rejectedAmount' | translate }} :
          {{ fetchRejectedOrderAmount() | customCurrency }}
        </h4>
      </ng-container>

      <ng-container *ngIf="fetchSubstituteOrderAmount() > 0">
        <h4 style="color: #ff4400">
          {{ 'SubstitueAmount' | translate }} :
          {{ fetchSubstituteOrderAmount() | customCurrency }}
        </h4>
      </ng-container>

      <h2>
        {{ 'GranTotal' | translate }} :
        {{ fetchGrandAmount() | customCurrency }}
      </h2>
    </div>
  </div>
</div>
