export const SideBarConst: Array<any> = [
  {
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'assets/images/dash.svg',
    isSubMenu: false
  },
  {
    text: 'Orders',
    path: '/orders',
    icon: 'assets/images/manage_order.svg',
    isSubMenu: true,
    subMenuItems: [
      {
        text: 'Orders',
        path: '/orders',
        icon: 'assets/images/ord3.svg',
        isSubMenu: false,
        sideBarId: 601
      },
      {
        text: 'Exchange/Return',
        path: '/orders/return/0/1',
        icon: 'assets/images/ord2.svg',
        isSubMenu: false,
        sideBarId: 602
      }
    ]
  },
  {
    text: 'Vehicles',
    path: '/vehicles',
    icon: 'assets/images/vehicles.svg',
    isSubMenu: false
  },
  {
    text: 'Drivers',
    path: '/drivers/list/1/0',
    icon: 'assets/images/driver.svg',
    isSubMenu: false
  },
  {
    text: 'Geofence',
    path: '/geofence',
    icon: 'assets/images/geofence.svg',
    isSubMenu: false
  },
  // {
  //   text: 'Teams',
  //   path: '/teams',
  //   icon: 'assets/images/team.svg',
  //   isSubMenu: false
  // },
  // {
  //   text: 'Tools',
  //   path: '/tools',
  //   icon: 'assets/images/tools.svg',
  //   isSubMenu: false
  // },
  // {
  //   text: 'Pricing',
  //   path: '/pricing',
  //   icon: 'assets/images/packaging_price.svg',
  //   isSubMenu: false
  // },
  // {
  //   text: 'Report',
  //   path: '/report',
  //   icon: 'assets/images/reports.svg',
  //   isSubMenu: false
  // },
  {
    text: 'Driver Earning',
    icon: 'assets/images/driver.svg',
    path: '/driverearning/1/0',
    isSubMenu: false
  },

  {
    text: 'Setting',
    icon: 'assets/images/settings.svg',
    path: '/settings',
    isSubMenu: false
  }
];
