export const responseStatus = {
  SUCCESS: 200,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOTFOUND: 404,
  REQUESTTIMEOUT: 408,
  INTERNALSERVERERROR: 500
};
