import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bazaarna-rejection-reason',
  templateUrl: './rejection-reason.component.html',
  styleUrls: ['./rejection-reason.component.scss']
})
export class RejectionReasonComponent {
  submitted = false;
  showError = false;
  rejectionReason = '';
  constructor(private dialogRef: MatDialogRef<RejectionReasonComponent>) {}

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  submit(): void {
    this.submitted = true;

    this.rejectionReason = this.rejectionReason.trim();

    if (this.rejectionReason === '') {
      this.showError = true;
    } else {
      this.dialogRef.close(this.rejectionReason);
    }
  }
}
