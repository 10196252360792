/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  socket: any;
  constructor() {
    // todo
  }

  initSocket(): void {
    if (
      localStorage.getItem(`${environment.storageKey}`) &&
      localStorage.getItem(`${environment.storageKey}`) !== undefined
    ) {
      this.socket = io(environment.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: localStorage.getItem(`${environment.storageKey}`)
        }
      });
    }
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on(event, (data: any) => observer.next(data));
    });
  }

  disconnect(): void {
    this.socket.disconnect();
  }
}
