/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'Bazaarna-add-vehicle',
  templateUrl: './add-vehicle.component.html'
})
export class AddVehicleComponent implements OnInit {
  submitted = false;
  vehicleFormGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.vehicleFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    if (this.data.type === 'edit') this.vehicleFormGroup.patchValue({
      name: this.data.data.name
    });
  }

  onSubmit(): void {
    this.submitted = true;

    this.vehicleFormGroup.patchValue({
      name: this.vehicleFormGroup.get('name')?.value.trim()
    });

    if (this.vehicleFormGroup.valid) {
      this.dialogRef.close(this.vehicleFormGroup.value);
    }
  }
}
