/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AdminUser } from '../models/adminUser';
import { ResetPassword } from '../models/resetPassword';
import { messageResponse } from '../models/messageResponse';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profileDetails: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(
    null
  );
  constructor(private readonly http: HttpClient) {}

  isAuthenticated(): boolean {
    if (
      localStorage.getItem(environment.storageKey) &&
      localStorage.getItem(environment.storageKey) !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  loginAdmin(payload: any): Observable<AdminUser> {
    return this.http
      .post<{ data: AdminUser }>(`${environment.base_url}admin/login`, payload)
      .pipe(map(res => res.data));
  }

  forgotPassword(payload: any): Observable<any> {
    return this.http
      .post<{ data: any }>(
        `${environment.base_url}admin/forgotPassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  resetPassword(payload: ResetPassword): Observable<messageResponse> {
    return this.http
      .post<{ data: messageResponse }>(
        `${environment.base_url}admin/resetPassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  changePassword(payload: any): Observable<any> {
    return this.http
      .post<{ data: any }>(
        `${environment.base_url}admin/changePassword`,
        payload
      )
      .pipe(map(res => res.data));
  }

  getAdminProfile(): Observable<any> {
    return this.http
      .get<{ data: any }>(`${environment.base_url}admin/getProfile`)
      .pipe(map(res => res.data));
  }

  updateAdminProfile(payload: any): Observable<any> {
    return this.http
      .put<{ data: any }>(`${environment.base_url}admin/updateProfile`, payload)
      .pipe(map(res => res.data));
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/admin/logout`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'You will be logged out!',
        icon: 'info',
        customClass: 'logout_dialog',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#000',
        cancelButtonColor: '#FF0000',
        confirmButtonText: 'Logout'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }
}
