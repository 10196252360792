<div class="modal_title">
  <h1>{{ 'AddNotification' | translate }}</h1>
</div>
<div class="tables">
  <div class="form_feilds">
    <div class="form_grup w_100">
      <mat-label>{{ 'NotificationSend' | translate }}</mat-label>
      <mat-form-field floatLabel="never">
        <mat-select placeholder="Notification Type">
          <mat-option value="1">All{{ 'ManageNotifications' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form_grup w_100">
      <mat-label>{{ 'NotificationTitle' | translate }}</mat-label>
      <mat-form-field appearance="fill">
        <input matInput placeholder="{{ 'Title' | translate }}" class="form_control" />
      </mat-form-field>
    </div>
    <div class="form_grup w_100">
      <mat-label>{{ 'NotificationMessage' | translate }}</mat-label>
      <mat-form-field appearance="fill">
        <textarea matInput placeholder="{{ 'Message' | translate }}" class="form_control"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="filter_lft">
    <button class="btn btn-primary">{{ 'Submit' | translate }}</button>
  </div>
</div>


