import { keyValuePair } from 'src/app/models/keyValuePair';

export const salaryTypeNumber = {
  SALARIED: 1,
  COMMISSIONED: 2,
  SALARIEDCOMMISSIONED: 3
};

export const salaryType : Array<keyValuePair> = [
  {
    key: 'Salary',
    value: salaryTypeNumber.SALARIED
  },
  {
    key: 'Commission',
    value: salaryTypeNumber.COMMISSIONED
  },
  {
    key: 'Salary + Commission',
    value: salaryTypeNumber.SALARIEDCOMMISSIONED
  }
];
