import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ToasterService } from 'src/app/services/toastr.service';
import { responseStatus } from '../const/responseStatus.const';
@Injectable()
export class GetInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private _toastrService: ToasterService) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // ToDo
          }
        },
        error: (error: HttpErrorResponse) => {
          switch (error['status']) {
          case responseStatus.BADREQUEST: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.UNAUTHORIZED:
          case responseStatus.FORBIDDEN: {
            this._toastrService.errToastr(error['error']['message']);
            localStorage.clear();
            this.router.navigate(['/auth/login']);
            break;
          }
          case responseStatus.REQUESTTIMEOUT: {
            this._toastrService.errToastr(error['error']['message']);
            break;
          }
          case responseStatus.INTERNALSERVERERROR:
            this._toastrService.errToastr(error['error']['message']);
            break;
          default:
            this._toastrService.errToastr(
              'Something went wrong... Try Checking your network connections!'
            );
            break;
          }
        }
      })
    );
  }
}
