import { Component } from '@angular/core';

@Component({
  selector: 'bazaarna-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent {
  constructor() {
    // ToDo
  }
}
