

  <div class="container-fluid dialog_vehicle">
    <div class="tabel-page">
      <div class="filter_sec">
        <div class="filter_lft">
          <div class="title-bar">
            <h1>
              {{
                data.type === 'add'
                  ? ('AddVehicle' | translate)
                  : ('EditVehicle' | translate)
              }}
            </h1>
          </div>
        </div>
      </div>

      <form [formGroup]="vehicleFormGroup" (ngSubmit)="onSubmit()">
        <div class="tables">
          <div class="form_feilds">
            <div class="form_grup w-100">
              <mat-label>
                {{ 'VehicleCategory' | translate }}
                <span class="astrick"></span
              ></mat-label>
              <mat-form-field appearance="fill">
                <input
                  matInput
                  placeholder="{{ 'VehicleCategory' | translate }}"
                  class="form_control"
                  formControlName="name" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  submitted &&
                  vehicleFormGroup.get('name')?.hasError('required')
                ">
                {{ 'VehicleCategory' | translate }}
                {{ 'IsRequired' | translate }}
              </mat-error>
            </div>
            <div class="form_grup"></div>
            <div class="filter_lft">
              <button class="btn btn-primary" type="submit">
                {{
                  data.type === 'add'
                    ? ('Save' | translate)
                    : ('Update' | translate)
                }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

