export const assetsConst = {
  addSvg: 'assets/images/add.svg',
  logo: 'assets/images/logo.svg',
  logo2: 'assets/images/logo2.svg',
  noDataFound: 'assets/images/Nodat-found.gif',
  user: 'assets/images/user.png',
  spinner: 'assets/images/0998.gif',
  driver: 'assets/images/driver.svg',
  order2: 'assets/images/ord2.svg',
  order4: 'assets/images/ord4.svg'
};
