<mat-sidenav-container fullscreen>
  <mat-sidenav
    #sidenav
    mode="side"
    class="example-sidenav"
    [ngStyle]="{ 'width.em': sidenavWidth }"
    opened="true">
    <bazaarna-sidebar></bazaarna-sidebar>
  </mat-sidenav>
  <div class="example-sidenav-content">
    <div class="top_header">
      <div class="toggle" (click)="sidenav.toggle()">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="top_header_ryt">
        <!-- <i
          class="fa fa-bell"
          aria-hidden="true"
          [matMenuTriggerFor]="menu1"></i>
        <mat-menu
          #menu1="matMenu"
          class="dropbox"
          xPosition="before"
          yPosition="below">
          <ul>
            <h4>{{ 'Notifications' | translate }}</h4>
            <ul>
              <li class="ntf-list" routerLink="/notification/notification">
                <figure>
                  <img src="assets/images/user.png" alt="profile" />
                </figure>
                <div class="ntf_dt">
                  <h3>John Doe</h3>
                  <p class="nt_typ">mentioned you in a comment</p>
                  <p>Lorem ipsum dolor sit amit</p>
                </div>
                <p class="ntf_tym">1 Day ago</p>
              </li>
              <li class="ntf-list" routerLink="/notification/newnotification">
                <figure>
                  <img src="assets/images/user.png" alt="profile" />
                </figure>
                <div class="ntf_dt">
                  <h3>John Doe</h3>
                  <p class="nt_typ">likes your profile</p>
                  <p>Lorem ipsum dolor sit amit</p>
                </div>
                <p class="ntf_tym">1 Day ago</p>
              </li>
              <li class="ntf-list" routerLink="/notification/notification">
                <figure>
                  <img src="assets/images/user.png" alt="profile" />
                </figure>
                <div class="ntf_dt">
                  <h3>John Doe</h3>
                  <p class="nt_typ">mentioned you in a comment</p>
                  <p>Lorem ipsum dolor sit amit</p>
                </div>
                <p class="ntf_tym">1 Day ago</p>
              </li>
            </ul>
            <div class="submit">
              <button class="see_link" routerLink="/received_notification">
                {{ 'SeeAll' | translate }}
              </button>
            </div>
          </ul>
        </mat-menu> -->
        <figure mat-button [matMenuTriggerFor]="menu">
          <img
            [src]="
              profileDetails && profileDetails.image
                ? profileDetails.image
                : assetsImages.user
            "
            alt="profile" />
        </figure>
        <mat-menu
          #menu="matMenu"
          class="dropbox"
          xPosition="before"
          yPosition="below">
          <ul>
            <!-- <li routerLink="/profile/profile">{{ 'MyProfile' | translate }}</li> -->
            <li routerLink="/password/change-paswd">
              {{ 'ChangePassword' | translate }}
            </li>
            <li (click)="logout()">{{ 'Logout' | translate }}</li>
          </ul>
        </mat-menu>
      </div>
    </div>
    <router-outlet></router-outlet>
    <mat-menu #menu="matMenu"> </mat-menu>
  </div>
</mat-sidenav-container>
