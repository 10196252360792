import { Injectable, NgModule } from '@angular/core';
import { CustomCurrencyPipe } from './custom-currency.pipe';

@Injectable({
  providedIn: 'root'
})
@NgModule({
  declarations: [CustomCurrencyPipe],
  exports: [CustomCurrencyPipe]
})
export class PipesModule {}
