/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  VERSION
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { assetsConst } from 'src/app/core/const/assets.const';
import { responseStatus } from 'src/app/core/const/responseStatus.const';
import { SideBarConst } from 'src/app/core/const/sideBar.const';
import { logoutResponse } from 'src/app/models/logoutResponse';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'bazaarna-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy {
  @Output() urlOutput = new EventEmitter<any>();

  panelOpenState = false;
  version = VERSION;
  sidenavWidth = 15;
  ngStyle!: string;
  assetsConst = assetsConst;
  sideBar = SideBarConst;
  destroy$: Subject<boolean> = new Subject<boolean>();
  permissions: any = [];
  constructor(
    private _authService: AuthService,
    private _spinnerService: SpinnerService,
    private router: Router
  ) {
    // ToDo
  }

  increase(): void {
    this.sidenavWidth = 15;
  }
  decrease(): void {
    this.sidenavWidth = 4;
  }

  logout(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._authService.logoutAlert().then((res: any) => {
      if (
        Object.prototype.hasOwnProperty.call(res, 'value') &&
        res.isConfirmed === true
      ) {
        this._spinnerService.addToLoader('logout');
        this._authService
          .logout()
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (res: logoutResponse) => {
              if (res.statusCode === responseStatus.SUCCESS) {
                localStorage.clear();
                this._spinnerService.removeFromLoader('logout');
                this.router.navigate(['/auth/login']);
              }
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
