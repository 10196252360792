import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocData } from '../models/docData';
import { DriverDocsResponse } from '../models/driverDocResponse';

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  DocsData: DocData = {
    documentName: '',
    isIssueRequired: false,
    isFrontImageRequired: false,
    isBackImageRequired: false,
    isExpiryRequired: false,
    _id: null,
    frontImage: '',
    backImage: '',
    expiryDate: '',
    issueDate: ''
  };

  constructor(private http: HttpClient) {}

  getDriver(query: string): Observable<any> {
    return this.http.get(`${environment.base_url}admin/getDriver?${query}`);
  }

  getDriverById(id: string): Observable<any> {
    return this.http.get(`${environment.base_url}admin/getDriverById/${id}`);
  }

  getAvailableDriver(id: string): Observable<any> {
    return this.http.get(
      `${environment.base_url}admin/driversThroughZone/${id}`
    );
  }

  addDriver(payload: any): Observable<any> {
    return this.http.post(`${environment.base_url}admin/driver`, payload);
  }

  updateDriver(payload: any, driverId: string): Observable<any> {
    return this.http.put(
      `${environment.base_url}admin/updateDriverById/${driverId}`,
      payload
    );
  }

  deleteDriver(id: string): Observable<number> {
    return this.http
      .delete<{ statusCode: number }>(
        `${environment.base_url}admin/deleteDriverById/${id}`
      )
      .pipe(map(res => res.statusCode));
  }

  getAllMerchants(): Observable<any> {
    return this.http.get(`${environment.base_url}admin/merchantDropdown`);
  }

  actionOnDriverApproval(payload: any, id: string): Observable<any> {
    return this.http.put(
      `${environment.base_url}admin/approveDriver/${id}`,
      payload
    );
  }

  getDriverDocs(): Observable<DriverDocsResponse> {
    return this.http
      .get<{ data: DriverDocsResponse }>(
        `${environment.base_url}admin/getDriverDocs`
      )
      .pipe(map(res => res.data));
  }

  getDriverEarning(query: string): Observable<any> {
    return this.http
      .get<{ data: any }>(
        `${environment.base_url}admin/driverEarining?${query}`
      )
      .pipe(map(res => res.data));
  }
}
